import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { initializePerformance } from 'firebase/performance';
import Styleclass from 'primevue/styleclass';
import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import router from '@/router';
import App from '@/App.vue';
import firebaseConfig from './helpers/firebaseConfig';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const firebaseApp = initializeApp(firebaseConfig);
setAnalyticsCollectionEnabled(getAnalytics(firebaseApp), false);
initializePerformance(firebaseApp, { instrumentationEnabled: true });

const vueApp = createApp(App);
vueApp.use(router);
vueApp.use(PrimeVue, { ripple: true, styleclass: true });
vueApp.use(ToastService);
vueApp.directive('styleclass', Styleclass);
vueApp.directive('ripple', Ripple);

vueApp.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

router.isReady().then(() => {
  vueApp.mount('#app');
});
