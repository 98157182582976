import { createRouter, createWebHistory } from 'vue-router';
import Error404Module from '@/components/Error404Module.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:catchAll(.*)?',
      name: 'error404',
      component: Error404Module,
      meta: {
        title: 'Page not found',
        auth: false,
        metaTags: [
          {
            name: 'robots',
            content: 'noindex',
          },
          {
            property: 'og:description',
            content: 'Page not found',
          },
        ],
      },
    },
  ],
});

export default router;
